@media(max-width: 700px) {
    .login-screen {
        .continer {
            .login-container {
                padding: 0 40px;
                width: 100%;
            }
        }
    }
}

@media (min-width: 1501px) {
    .login-screen {
        .continer {
            background-image: url("./background/large/login-back.jpg");

            .login-container {
                max-width: 640px;
                height: 512px;
            }
        }
    }
}
@media (max-width: 1500px) {
    .login-screen {
        .continer {
            background-image: url("./background/small/login-back.jpg");

            .login-container {
                max-width: 512px;
                height: 320px;
            }
        }
    }
}

.login-screen {

    .continer {
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position-x: center;
        background-color: #F5DDD4;
        padding-top: 128px;

        .login-container {
            margin: auto;
            display: flex;
            flex-direction: column;
    
            .email-input {
                flex: 3;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
    
            .error {
                flex: 2;
                padding-top: 15px;
    
                span {
                    color: red;
                    font-size: 14px;
                }
            }
    
            .action {
                flex: 2;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0 20%;
            }
        }
    }
}