.link-container {
    padding: 0 20px;
}

.groupName {
    margin-top: 20px;
    margin-bottom: 5px;;
}

.link {
    padding: 10px 20px;
    background-color: lightgray;
    border-radius: 3px; 
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
        background-color: #E0E0E0;
    }

    &:active {
        background-color: #b5b5b5;
    }
}

.link-title {
    color: black;
    font-size: 16px;
    margin-bottom: 5px;
}

.selector-label {
    text-align: right;
}

.sessions-container {
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow: scroll;
    align-items: center;
}

.session-item {
    margin-top: 10px;
    padding: 10px;
    background-color: lightgray;
    border-radius: 5px;

    span{
        font-size: 16px;
        color: black;
    }
}

.filter-item {
    width: 300px;
}

.nav-item {
    cursor: pointer;
}

.additional-info {
    color: gray;
    font-weight: bold;
}