@media only screen and (min-height: 640px) {
    .top-gap, .bottom-gap {
        height: 100px;
    }
}

@media only screen and (max-height: 640px) {
    .top-gap, .bottom-gap {
        height: 64px;
    }
}

#rating-main-container::-webkit-scrollbar{
    display: none;
}