.provider-info {
    display: flex;
    margin-top: 10px;

    .label {
        width: 100px;
    }

    .content {
        flex: 1;
    }
}

.item-label {
    font-size: 18px;
    font-weight: bold;
}

.firesidechat {
    img.firesidechat-logo {
        float: left;
        max-width: 400px;
        height: auto;
        margin-right: 16px;
        cursor: pointer;
    }

    p {
        margin-bottom: 7px;
    }
}