
@media only screen and (max-width: 768px) {
    .video-player {
        width: calc(100% - 40px);
        max-width: unset;
    }
}

@media only screen and (min-width: 768px) {
    .video-player {
        width: 720px;
        max-width: unset;
    }
}

.player-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;
}