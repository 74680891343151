.op-loading {
  animation: Loading-spin infinite 0.7s linear;
}

@keyframes Loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn_flat {
  background: transparent;
  border: 0;
  color: #5e72e4;
}

.main-container {
  padding-top: 22px;

  .page-topic {
    color: black;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;

    &.title-link {
      color: blue;
      font-size: 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .main-tabs {
    button {
      margin-top: 5px !important;
    }
  }

  .v-center {
    display: flex;
    align-items: center;
  }
}

.general-container {
  padding: 10px 0;
  padding-bottom: 50px;
}

@media (min-width: 991.98px) {
  .general-container {
    padding: 10px 0;
    padding-bottom: 450px;
  }
}

.loading-container {
  padding: 20px;
}

.nolist-placeholder {
  padding: 20px 20px 0 20px;

  span {
    font-size: 20px;
    color: lightgray;
  }
}

.session-item {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  background: #f7f7f7;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }

  .session-item-title {
    span {
      font-size: 16px;
      color: black;
    }
  }

  .session-item-tags {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;

    .session-item-tag {
      margin: 5px 0;
      margin-right: 10px;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .blue-tag {
    background: lightblue;
  }

  .green-tag {
    background: lightgreen;
  }

  .pink-tag {
    background: lightpink;
  }

  .darkred-tag {
    background: #cc99aa;
  }
  
  .yellow-tag {
    background: #cbdc60;
  }

  .brown-tag {
    background: #FFA600;
  }

  .red-tag {
    background-color: rgb(255, 104, 104);
  }
}

.contact-item {
  width: 100%;
  padding: 10px 20px;
  background: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  .contact-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;

    & > :not(:first-child) {
      margin-left: 5px;
    }
  }

  &:hover {
    background: #f7f7f7;
  }

  .name {
    font-size: 20px;
    color: black;
    font-weight: bold;
  }

  .phone {
    padding: 3px 10px;
    background: lightgreen;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    cursor: pointer;

    &:hover {
      background: rgb(119, 196, 119);
    }
  }

  .email {
    padding: 3px 10px;
    background: lightblue;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    cursor: pointer;

    &:hover {
      background: rgb(132, 164, 175);
    }
  }

  .booked-sessions {
    padding: 3px 10px;
    background: lightpink;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    cursor: pointer;

    &:hover {
      background: rgb(205, 146, 155);
    }
  }
}

.multicheck-container {
  margin-bottom: 10px;

  .multicheck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .multicheck-item {
      padding: 5px 10px;
      background: lightgray;
      border-radius: 5px;
      margin: 7px;
      cursor: pointer;
      font-size: 12px;
      color: black;

      &:hover {
        background: #f0f0f0;
      }
    }
  }
}

.item-checked {
  background: #ff5d5d !important;
  color: white !important;

  &:hover {
    background: #ff5d5d !important;
  }
}

.search-session {
  margin-top: 10px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;

  i {
    color: #f0f0f0;

    &.active {
      color: red;
    }
  }

  &:hover {
    background: #dedede;

    i {
      color: #dedede;
    }

    i.active {
      color: red;
    }
  }

  .title {
    font-size: 16px;
    color: black;
  }
}

.tag-session {
  margin-top: 10px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #dedede;
  }

  .title {
    font-size: 16px;
    color: black;
  }
}

.modal-body .session-info {
  .session-image {
    position: relative;
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.session-info {
  .session-info-row {
    position: relative;
  }

  .session-image {
    float: right;
    max-width: 320px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .session-info-form-group {
    align-items: center;
    padding: 0 20px;
  }

  .session-info-item {
    margin-top: 10px;

    span.info-title {
      color: gray;
      font-size: 14px;
    }

    a.info-link {
      span.info-content {
        cursor: pointer;
        color: blue;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    span.info-content {
      color: black;
      white-space: pre-line;
    }

    div.info-content {
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: white;
      font-size: 14px;

      .tag-item {
        padding: 5px 10px;
        border-radius: 5px;
        margin: 10px;
        cursor: pointer;

        span {
          color: white;
          font-size: 14px;
        }
      }

      .subject-tag {
        background: brown;

        &:hover {
          background: rgb(192, 57, 57);
        }
      }

      .grade-tag {
        background: green;

        &:hover {
          background: rgb(47, 167, 47);
        }
      }
    }
  }
}

.form-style {
  color: black !important;
  font-size: 14px !important;
}

.form-input {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  color: black;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flexview {
  display: flex;
  flex-direction: row;

  .orview {
    label {
      color: white;
    }

    .orspan {
      height: 42px;
      display: flex;
      align-items: center;
      padding-right: 10px;

      span {
        color: #8a8d94;
      }
    }
  }
}

.file-view {
  width: 100px;
  height: 100px;
  background-color: lightgray;
  position: relative;
  border-radius: 5px;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  // cursor: zoom-in;

  .file-view-container {
    span {
      display: inline-block;
      width: 80px;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      color: black;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
  }

  .btn-remove {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #ff5d5d;
    cursor: pointer;
    padding-left: 6px;
    padding-top: 1px;
    font-size: 12px;
    color: black;

    &:hover {
      background-color: #fe8383;
    }

    &:active {
      background-color: #fc2c2c;
    }
  }

  .btn-download {
    position: absolute;
    top: -10px;
    right: 15px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #60d8ab;
    cursor: pointer;
    padding-left: 4px;
    padding-top: 2px;
    font-size: 12px;
    color: black;

    &:hover {
      background-color: #7ae0bb;
    }

    &:active {
      background-color: #41d49e;
    }
  }
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  background-color: black;
  border-radius: 4px;
}

.acknowledgements {
  color: #5e72e4;
  cursor: pointer;
}

button.modal-action {
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 40px;
}

.photo-session-filter {
  margin-bottom: 10px;
}

.photo-session-container {
  margin-top: 10px;
  -moz-column-count: 3;
  -moz-column-gap: 10px;
  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  column-count: 3;
  column-gap: 16px;

  .photo-session-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
    border: 4px solid transparent;

    img {
      width: 100%;
      height: auto;
      cursor: pointer;

      &:hover {
        border: 4px solid #5e72e4;
      }

      &:active {
        border: 4px solid #3a478e;
      }
    }
  }
}

.session-loading {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.short-desc {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #525f7f;
  margin: 10px 0 0 0;
}

.tag-group {
  margin-top: 15px;

  span {
    font-size: 20px;
    font-weight: bold;
  }
}

.favorite {
  .subject-name {
    font-size: 20px;
    font-weight: 700;
    color: #525f7f;
  }

  .favorite-session {
    margin-top: 5px;
    padding: 5px 10px;
    background: #f0f0f0;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: #dedede;
    }

    .title {
      font-size: 16px;
      color: black;
      font-weight: bold;
    }
  }
}

i.favorite {
  color: red;
}

.review {
  color: black;
  margin: 5px 0;
}

.toasts-container {
  display: block !important;
  .toast {
    padding: 10px 15px;
  }
}

.quotecallout {
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 32px;
    border-left-style: none;
    border-radius: 10px;
    background-color: #e8edf4;
    color: #000;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
}


@media (min-width: 1024px) {
  .modal-dialog {
    &.session-modal {
      max-width: 800px;
    }
  }
}

.session-warning {
  margin: 20px 0;
  background: #d81463;
  padding: 8px 16px;
  border-radius: 8px;

  span {
    color: #e5e5e5;
    font-size: 14px;
  }
}

.indigenous-group {
  margin: 20px 0 5px 0;

  span {
    font-weight: bold;
    font-size: 24px;
    color: black;
  }
}