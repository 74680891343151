.ms-part {
    margin-top: 20px;

    span.title {
        color: black;
        font-weight: bold;
        text-decoration: underline;
        font-size: 16px;
    }

    .ms-part-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .pronouns {
            max-width: 320px;
        }

        .multicheck-item {
            padding: 5px 10px;
            background: lightgray;
            border-radius: 5px;
            margin: 7px;
            cursor: pointer;
            font-size: 12px;
            color: black;

            &.item-checked {
                background: #ff5d5d !important;
                color: white !important;
            }
        }
    }
}

.ms-action {
    padding-top: 20px;
    margin: auto;
    text-align: center;
}