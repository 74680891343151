.presenter-container {
    margin: 10px 0;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
        font-size: 18px;
    }
}

.label {
    font-size: 16px;
}

.fixed-size {
    width: 166px !important;
}

.no-img {
    width: 200px;
    height: 300px;
    border: 1px dashed gray;
    border-radius: 10px;
}