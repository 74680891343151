
.assign-presenter-modal {
    width: 360px !important;

    .modal-body {
        padding: 0;

        .checked {
            color: green;
            min-width: 40px;

            svg {
                font-size: 25px;
            }
        }

        .presenter-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 0.875rem;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}