.general-container {
    .buttons {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .btn-session {
            width: 132px;
            margin: 3px 0 !important;
        }
    }

    .monthly-group {
        margin: 20px 0 0px 0px;
    }
}